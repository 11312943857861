export const compressImage = (
  file: File,
  circle = false,
  maxWidth = 230,
  maxHeight = 180
): Promise<Blob | null> => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  return new Promise((resolve) => {
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const [newWidth, newHeight] = calculateSize(img, maxWidth, maxHeight);
      canvas.width = newWidth;
      canvas.height = newHeight;
      if (ctx) {
        if (circle) {
          ctx.beginPath();
          ctx.arc(
            newWidth / 2,
            newHeight / 2,
            Math.min(newWidth, newHeight) / 2,
            0,
            2 * Math.PI
          );
          ctx.clip();
        }
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
      }
      canvas.toBlob(resolve);
    };
  });
};

const calculateSize = (
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
): number[] => {
  let { width, height } = img;
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
};
