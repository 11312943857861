import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b12e292c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "cropper--wrapper" }
const _hoisted_3 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "main-modal crop-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Choose view"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("p", _hoisted_1, _toDisplayString($props.description), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["Loader"], {
            loading: !$setup.imgSrc || $setup.loading
          }, null, 8, ["loading"]),
          ($setup.imgSrc)
            ? (_openBlock(), _createBlock($setup["VueCropper"], {
                key: 0,
                class: _normalizeClass(["cropper", {circle: $props.aspectRatio === 1}]),
                ref: "cropperRef",
                src: $setup.imgSrc,
                onReady: $setup.onReady
              }, null, 8, ["src", "class"]))
            : _createCommentVNode("", true)
        ])
      ], true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["NButton"], {
          class: "small-button full-width-button",
          type: "info",
          round: "",
          onClick: $setup.cropImage,
          disabled: $setup.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode("Save")
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _: 3
  }))
}